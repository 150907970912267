<template>
  <b-card body-class="pb-50">
    <h6>Product Trends by Month</h6>
    <vue-apex-charts
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: 'Product A Trends',
          data: [50, 40, 35, 50, 49, 60, 70, 91, 125, 150]
        },
        {
          name: 'Product B Trends',
          data: [30, 60, 55, 70, 19, 80, 30, 61, 145, 170]
        }
      ],
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        },
        yaxis: {
          title: {
            text: 'Count'
          }
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        colors: ['#7367F0', '#00CFE8'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: 'top'
        }
      },
    };
  },
}
</script>

<style>
/* Add any required styles here */
</style>
